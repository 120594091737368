<template>
  <v-container fluid>
    <TheTitle text="Configuração de Menus" icon="mdi-group">
      <v-btn depressed color="primary" dark @click="dialogSave()">
        <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar</v-btn
      >
    </TheTitle>
    <v-card class="pa-5" flat>
      <v-data-table
        :headers="headerMenu"
        :items="menus"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        dense
      >
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Adicionar sub Menu">
            <v-btn icon small tile>
              <v-icon @click="addSubMenu(item)">mdi-note-plus</v-icon>
            </v-btn>
          </TheToolTip>

          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>

          <TheToolTip label="Remover" color="red">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>

        <template v-slot:[`item.icon`]="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="py-3">
            <v-data-table
              :headers="headerMenuItem"
              :items="item.menuItem"
              item-key="id"
              dense
              hide-default-footer
            >
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <TheToolTip label="Editar">
                  <v-btn icon small tile>
                    <v-icon @click="editSubMenu(item)">mdi-pen</v-icon>
                  </v-btn>
                </TheToolTip>

                <TheToolTip label="Remover" color="red">
                  <v-btn icon small tile>
                    <v-icon @click="removeMenuItem(item.id)">mdi-minus</v-icon>
                  </v-btn>
                </TheToolTip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <!-- Diálogo para cadastro e alteração de menus -->
    <TheDialog v-model="dialog" title="Menu">
      <template>
        <v-form v-model="valid1" ref="form1">
          <b>Nome<sup>*</sup></b>
          <v-text-field
            placeholder="Nome"
            outlined
            :rules="requiredRule"
            v-model="menu.name"
            class="mt-2"
          ></v-text-field>

          <v-row>
            <v-col cols="12" md="6">
              <b>Rota</b>
              <v-text-field
                placeholder="Rota"
                outlined
                v-model="menu.route"
                class="mt-2"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <b>ícone<sup>*</sup></b>
              <v-text-field
                placeholder="Ícone"
                outlined
                :rules="requiredRule"
                v-model="menu.icon"
                class="mt-2"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
      </template>
    </TheDialog>
    <!-- Diálogo para cadastro e alteração de subMenus -->
    <TheDialog v-model="dialogSubMenu" title="Submenu">
      <template>
        <v-form v-model="valid2" ref="form2">
          <b>Nome<sup>*</sup></b>
          <v-text-field
            placeholder="Nome"
            outlined
            :rules="requiredRule"
            v-model="menuItem.name"
            class="mt-2"
          ></v-text-field>

          <v-row>
            <v-col cols="12" md="6"
              ><b>Rota<sup>*</sup></b>
              <v-text-field
                placeholder="Rota"
                outlined
                :rules="requiredRule"
                v-model="menuItem.route"
                class="mt-2"
              ></v-text-field
            ></v-col>

            <v-col cols="12" md="6">
              <b>ícone<sup>*</sup></b>
              <v-text-field
                placeholder="Ícone"
                outlined
                :rules="requiredRule"
                v-model="menuItem.icon"
                class="mt-2"
              ></v-text-field
            ></v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveFormSubMenu()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'
import TheDialog from '@/components/TheDialog'
import TheToolTip from '@/components/TheToolTip'

export default {
  name: 'Route',
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },
  data: () => ({
    dialog: false,
    dialogSubMenu: false,
    path: 'menu',
    menuItemPath: 'menuItem',
    menu: {},
    menuItem: {},
    headerMenu: [
      { text: 'Nome', value: 'name' },
      { text: 'Rota', value: 'route' },
      { text: 'ícone', value: 'icon' },
      { text: 'Ações', value: 'action', width: '10%' },
      { text: '', value: 'data-table-expand' },
    ],
    headerMenuItem: [
      { text: 'Nome', value: 'name' },
      { text: 'Rota', value: 'route' },
      { text: 'ícone', value: 'icon' },
      { text: 'Ações', value: 'action', width: '10%' },
    ],
    verbs: ['get', 'post', 'put', 'delete'],
    menus: [],
    menuItens: [],
    error: false,
    edit: false,
    valid1: false,
    valid2: false,
    requiredRule: [(v) => !!v || 'O Campo é necessário'],
  }),
  methods: {
    addSubMenu(item) {
      this.menuItem = {}
      this.menuItem.idMenu = item.id
      this.dialogSubMenu = true
      this.edit = false
      if (this.$refs.form2) {
        this.$refs.form2.resetValidation()
      }
    },
    editSubMenu(item) {
      this.menuItem = item
      this.dialogSubMenu = true
      this.edit = true
      if (this.$refs.form2) {
        this.$refs.form2.resetValidation()
      }
    },
    loadData() {
      const url = `${baseApiUrl}/${this.path}`
      axios
        .get(url)
        .then((res) => {
          this.menus = res.data
        })
        .catch(showError)
    },
    loadMenuItemData() {
      const url = `${baseApiUrl}/${this.menuItemPath}`
      axios
        .get(url)
        .then((res) => {
          this.menuItens = res.data
        })
        .catch(showError)
    },
    dialogSave() {
      this.menu = {}
      this.edit = false
      this.dialog = true
      if (this.$refs.form1) {
        this.$refs.form1.resetValidation()
      }
    },
    dialogUpdate(item) {
      this.menu = item
      this.edit = true
      this.dialog = true
      if (this.$refs.form1) {
        this.$refs.form1.resetValidation()
      }
    },
    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid1) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text:
            'Os campos não foram preenchidos corretamente, você pode os verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    resolveFormSubMenu() {
      this.$refs.form2.validate()
      if (!this.valid2) {
        this.$store.dispatch('setErrorInfo', {
          open: true,
          text:
            'Os campos não foram preenchidos corretamente, você pode os verificar?',
          button: 'Ok!',
        })
        return
      }
      if (this.edit) {
        this.updateMenuItem()
      } else {
        this.saveMenuItem()
      }
    },
    saveMenuItem() {
      this.error = false
      const url = `${baseApiUrl}/${this.menuItemPath}`
      const newMenuItem = this.menuItem
      axios
        .post(url, newMenuItem)
        .then(() => {
          this.loadData()
          this.loadMenuItemData()
        })
        .catch(showError)
        .finally(() => {
          this.dialogSubMenu = false
        })
    },
    updateMenuItem() {
      this.error = false
      const id = this.menuItem.id
      const url = `${baseApiUrl}/${this.menuItemPath}/${id}`
      const updatedMenu = this.menuItem
      delete updatedMenu.id
      delete updatedMenu.createdAt
      delete updatedMenu.updatedAt
      delete updatedMenu.deletedAt

      axios
        .put(url, updatedMenu)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialogSubMenu = false
        })
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.path}`
      const newMenu = this.menu
      axios
        .post(url, newMenu)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.menu.id
      const url = `${baseApiUrl}/${this.path}/${id}`
      const updatedMenu = this.menu
      delete updatedMenu.id
      delete updatedMenu.createdAt
      delete updatedMenu.updatedAt
      delete updatedMenu.deletedAt
      delete updatedMenu.menuItem

      axios
        .put(url, updatedMenu)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    remove(id) {
      if (confirm('Remove?')) {
        const url = `${baseApiUrl}/${this.path}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
    removeMenuItem(id) {
      if (confirm('Remover?')) {
        const url = `${baseApiUrl}/${this.menuItemPath}/${id}`
        axios
          .delete(url)
          .then(() => {
            this.loadData()
          })
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
    this.loadMenuItemData()
  },
}
</script>

<style></style>
